import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Hint, SliderToggle } from 'components';

const Heading = styled.h3`
  font-weight: 600;
`;

const ToggleLabel = styled.label`
  display: flex;
  margin-bottom: 1rem;
`;

const P = styled.p`
  margin-bottom: 0;
`;

const WidgetBuilderHeadingToggle = ({
  configurations,
  headingText,
  description,
  setConfigurations,
  tooltipText,
  configurationKey
}) => {
  return (
    <Fragment>
      <ToggleLabel htmlFor={`${headingText}-toggle`}>
        <Heading>{headingText}</Heading>
        {!!tooltipText && <Hint id={`${configurationKey}-tooltip`}>{tooltipText}</Hint>}
      </ToggleLabel>
      {!!description && <P>{description}</P>}
      <SliderToggle
        id={`${headingText}-toggle`}
        ariaLabel={`${configurations[configurationKey] ? 'disable' : 'enable'} ${headingText}`}
        dataCy={`${configurationKey}-slider`}
        handleChange={() =>
          setConfigurations({
            ...configurations,
            [configurationKey]: !configurations[configurationKey]
          })
        }
        isChecked={configurations[configurationKey]}
      />
    </Fragment>
  );
};

WidgetBuilderHeadingToggle.propTypes = {
  configurations: PropTypes.object.isRequired,
  description: PropTypes.string,
  headingText: PropTypes.string.isRequired,
  setConfigurations: PropTypes.func.isRequired,
  tooltipText: PropTypes.string,
  configurationKey: PropTypes.string.isRequired
};

export default WidgetBuilderHeadingToggle;
