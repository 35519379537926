import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect, { components } from 'react-select';

import { color } from '../../../styles';
import { locale } from '../../../helpers/locale';
import Pencil from '../../../images/pencil.svg';
import { fetchInstitutions } from 'services';
import {
  BoldLabel,
  Loading,
  WidgetBuilderProgramsEmptyState,
  ProgramWidgetBuilderProgramModal,
  WidgetBuilderSelectedPrograms
} from 'components';
import Arrow from '../../atoms/Arrow';

const { sapphireD20 } = color;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const EditProgramsButton = styled.button`
  border: none;
  background: none;
  color: ${sapphireD20};
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0;
`;

const EditProgramsIcon = styled(Pencil)`
  height: 1.7rem;
  width: 1.7rem;
  position: relative;
  top: 0.2rem;
  margin-right: 0.75rem;
`;

const Label = styled.label`
  margin: 0 0 2rem 0;
`;

const handleChangeInstitution = ({
  newInstitutionId,
  selectedPrograms,
  setSelectedInstitution,
  setSelectedPrograms
}) => {
  let shouldChangeInstitution = true;
  if (!!newInstitutionId && selectedPrograms.length > 0) {
    const nonInstitutionSelectedPrograms = selectedPrograms.filter(
      ({ institutionId }) => institutionId !== newInstitutionId
    );

    if (nonInstitutionSelectedPrograms.length > 0) {
      shouldChangeInstitution = confirm(
        `It looks like you currently have a program selected from another institution. By selecting this institution, you're limiting the program listing to reflect only its programs`
      );
      if (shouldChangeInstitution) {
        setSelectedPrograms(
          selectedPrograms.filter(({ institutionId }) => institutionId === newInstitutionId)
        );
      }
    }
  }

  if (shouldChangeInstitution) {
    return setSelectedInstitution(newInstitutionId);
  }
};

const ProgramWidgetBuilderPrograms = ({ selectedPrograms, setSelectedPrograms, siteSettings }) => {
  const { currentSite, hasManyInstitutions = false, nation } = siteSettings;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [institutionsById, setInstitutionsById] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState('pristine');
  const [institutionSelectOptions, setInstitutionSelectOptions] = useState();

  const toggleIsModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const fetchInstitutionData = async () => {
    const institutionsResponse = await fetchInstitutions(currentSite);
    setInstitutionsById(institutionsResponse);

    const newInstitutionSelectOptions = Object.values(institutionsResponse).map(
      ({ displayName, ipeds }) => ({
        label: displayName,
        value: ipeds
      })
    );

    setInstitutionSelectOptions([{ label: 'All', value: false }, ...newInstitutionSelectOptions]);
  };

  useEffect(() => {
    if (hasManyInstitutions) {
      fetchInstitutionData();
    }
  }, []);

  const arePrograms = !!selectedPrograms.length;

  return (
    <Wrapper>
      <Header>
        <BoldLabel label={`${locale.edTrack[nation]}s`} component="legend" />
        {arePrograms && (
          <EditProgramsButton
            onClick={toggleIsModalOpen}
            aria-label={`Open the select ${locale.edTrack[nation]}s modal to add ${locale.edTrack[nation]}s to the widget.`}
          >
            <EditProgramsIcon aria-hidden="true" />
            {`Edit ${locale.edTrack[nation]}s`}
          </EditProgramsButton>
        )}
      </Header>
      <Loading loaded={!hasManyInstitutions || !!institutionsById}>
        {hasManyInstitutions && (
          <Label htmlFor="selected-institution">
            Select Institution
            <ReactSelect
              clearable={false}
              id="selected-institution"
              value={
                institutionSelectOptions?.find(option => option.value === selectedInstitution) ||
                null
              }
              onChange={option => {
                handleChangeInstitution({
                  newInstitutionId: option.value,
                  selectedPrograms,
                  setSelectedInstitution,
                  setSelectedPrograms
                });
              }}
              options={institutionSelectOptions}
              components={{
                IndicatorSeparator: () => null
              }}
            />
          </Label>
        )}
        {!arePrograms && (
          <div>
            <WidgetBuilderProgramsEmptyState
              nation={nation}
              isDisabled={hasManyInstitutions && selectedInstitution === 'pristine'}
              toggleIsModalOpen={toggleIsModalOpen}
            />
          </div>
        )}
        {arePrograms && (
          <WidgetBuilderSelectedPrograms
            hasManyInstitutions={hasManyInstitutions}
            institutionsById={institutionsById}
            selectedPrograms={selectedPrograms}
            setSelectedPrograms={setSelectedPrograms}
          />
        )}
        <ProgramWidgetBuilderProgramModal
          currentSite={currentSite}
          selectedInstitution={selectedInstitution}
          hasManyInstitutions={hasManyInstitutions}
          institutionsById={institutionsById}
          isOpen={isModalOpen}
          nation={nation}
          toggleIsModalOpen={toggleIsModalOpen}
          selectedPrograms={selectedPrograms}
          setSelectedPrograms={setSelectedPrograms}
        />
      </Loading>
    </Wrapper>
  );
};

ProgramWidgetBuilderPrograms.propTypes = {
  selectedPrograms: PropTypes.array.isRequired,
  setSelectedPrograms: PropTypes.func.isRequired,
  siteSettings: PropTypes.shape({
    currentSite: PropTypes.string.isRequired,
    nation: PropTypes.string.isRequired
  })
};

export default ProgramWidgetBuilderPrograms;
