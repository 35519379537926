import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Message from '../Message';
import { color } from '../../../styles';

const { red } = color;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  margin: 0 1rem 0 0;
  flex-grow: 1;
  border-radius: 3px;
`;

const StyledSelect = styled.select`
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  padding: 1rem;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  background: ${({ background }) => background};
  font-size: 1.5rem;
  width: 100%;
  border-radius: 3px;
  border: ${({ border, error }) => (error ? `1px solid ${red}` : border)};

  ::-ms-expand {
    display: none;
  }

  :focus {
    border: 1px solid #3e6dcc;
  }
`;
// By default Chrome has these options, but Edge/IE10/IE11 doesn't
const StyledOption = styled.option`
  color: black;
  background: #fff;
`;

const Icon = styled.i`
  position: absolute;
  pointer-events: none;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: #000;
`;

const StyledMessage = styled(Message)`
  font-size: 1.3rem;
  position: absolute;
  right: 0;
  top: 100%;
`;

const Select = ({
  ariaLabel = '',
  background = '#eee',
  border = '1px solid #eeeeee',
  className, // used in the SimpleSearchInput to handle the global header select
  dataCy,
  dataTheme,
  disabled,
  error = false,
  id,
  message,
  onChange,
  options,
  placeholder = '',
  selection,
  name
}) => {
  const selectedValue = typeof selection === 'object' ? selection.value : selection;
  const value = selectedValue || placeholder;
  return (
    <Wrapper className={className} data-select>
      <StyledSelect
        id={id}
        onChange={onChange}
        value={value}
        aria-label={ariaLabel}
        background={background}
        border={border}
        data-theme={dataTheme}
        data-cy={dataCy}
        error={error}
        disabled={disabled}
        name={name}
      >
        {placeholder && (
          <StyledOption className="default" disabled>
            {placeholder}
          </StyledOption>
        )}
        {options.map((option, index) => {
          return (
            <StyledOption key={`option-${index}`} value={option.value}>
              {option.label}
            </StyledOption>
          );
        })}
      </StyledSelect>
      <Icon className="ion-android-arrow-dropdown" aria-hidden="true" data-theme={dataTheme} />
      {error && message && (
        <StyledMessage type="error" data-cy={`${dataCy}-error`}>
          {message}
        </StyledMessage>
      )}
    </Wrapper>
  );
};

Select.propTypes = {
  ariaLabel: PropTypes.string,
  ariaLabelledby: PropTypes.string,
  background: PropTypes.string,
  border: PropTypes.string,
  dataCy: PropTypes.string,
  dataTheme: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  selection: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]).isRequired
};

export default Select;
