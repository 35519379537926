import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import {
  InfoModal,
  CheckboxGroup,
  NoTableData,
  Paginate,
  SearchInput,
  Table,
  TextButton,
  Truncate
} from 'components';
import { sortStrings } from '../../../helpers/sort';
import { locale } from '../../../helpers/locale';
import { withNationHOC } from '../../../hocs/withNation';

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const Filters = styled.div`
  margin-top: 3rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTable = styled(Table)`
  min-width: 75rem;
`;

class PostingsTable extends React.Component {
  state = {
    checkedJobTypes: this.props.enabledJobTypes,
    searchTerm: ''
  };
  render() {
    // extract the changePage function out from reactBootstrapTable.option.pagination.changePage
    // so that we can reset page number to 1 dynamically, when changing sorting and filtering
    let changePage;
    const {
      data,
      employers,
      enabledJobTypes,
      modalShow,
      nation,
      options,
      postingType,
      tableColumns = [],
      ...props
    } = this.props;
    const { searchTerm, checkedJobTypes } = this.state;
    const defaultColumns = [
      {
        dataField: 'title',
        text: 'Job Title',
        dataFormat: (cell, row) => (
          <Fragment>
            <TextButton onClick={() => modalShow(`posting${row.postingId}`)}>{cell}</TextButton>
            {this.props.modals[`posting${row.postingId}`] && (
              <InfoModal
                name={`posting${row.postingId}`}
                title={row.title}
                entryKeys={[
                  'title',
                  'description',
                  'jobType',
                  'endOn',
                  'careers',
                  'application.type',
                  { value: 'application.buttonText', label: 'application button text' },
                  'application.instructions'
                ]}
                row={{
                  ...row,
                  endOn: moment(row.endOn, 'MM-DD-YYYY').format(locale.date[nation])
                }}
              />
            )}
          </Fragment>
        )
      },
      {
        dataField: 'postingId',
        hidden: true,
        isKey: true,
        text: 'key column'
      },
      {
        dataField: 'employerName',
        text: 'Employer',
        sortFunc: sortStrings,
        dataFormat: (cell, row) =>
          employers[row.employer.id] ? (
            <Fragment>
              <TextButton onClick={() => modalShow(`employer${row.postingId}`)}>{cell}</TextButton>
              {this.props.modals[`employer${row.postingId}`] && (
                <InfoModal
                  name={`employer${row.postingId}`}
                  title={employers[row.employer.id].name}
                  entryKeys={['email', 'description', 'website', 'location', 'phoneNumber']}
                  row={employers[row.employer.id]}
                />
              )}
            </Fragment>
          ) : (
            <Truncate>{cell}</Truncate> || '---'
          )
      }
    ];
    return (
      <TableWrapper>
        <Filters>
          <SearchInput
            aria-label="search postings by job, employer or location"
            placeholder="Search by job, employer or location"
            onChange={e => this.setState({ searchTerm: e.target.value })}
          />
          <CheckboxGroup
            options={enabledJobTypes}
            checkedOptions={checkedJobTypes}
            onChange={array => this.setState({ checkedJobTypes: array })}
          />
        </Filters>
        {this.filterPostings(data).length > 0 ? (
          <StyledTable
            pagination
            data={this.filterPostings(data)}
            columns={[...defaultColumns, ...tableColumns]}
            options={{
              ...options,
              page: 1,
              sizePerPage: 10,
              paginationPanel: pagination => {
                changePage = pagination.changePage;
                return (
                  <Paginate
                    page={pagination.currPage}
                    total={this.filterPostings(data).length}
                    limit={10}
                    goToPage={pagination.changePage}
                  />
                );
              },
              onSortChange: () => changePage(1)
            }}
            {...props}
          />
        ) : (
          <NoTableData
            statusType={postingType}
            tableType="postings"
            isFiltered={!!searchTerm || checkedJobTypes.length < 4}
          />
        )}
      </TableWrapper>
    );
  }
  /** Convert postings from object to array, and search each posting's job title, employer location, or employer name for keyword */
  filterPostings = data => {
    const postings = Object.values(data);
    let { searchTerm, checkedJobTypes } = this.state;
    let filtered = postings.filter(item =>
      checkedJobTypes.map(jobType => jobType.toLowerCase()).includes(item.jobType.toLowerCase())
    );
    if (searchTerm) {
      searchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(
        i =>
          i.title.toLowerCase().includes(searchTerm) ||
          i.employer.name.toLowerCase().includes(searchTerm) ||
          (i.employer.location && i.employer.location.toLowerCase().includes(searchTerm))
      );
    }
    return filtered;
  };
}

PostingsTable.propTypes = {
  data: PropTypes.object.isRequired,
  employers: PropTypes.object.isRequired,
  enabledJobTypes: PropTypes.array.isRequired,
  modals: PropTypes.object.isRequired,
  modalShow: PropTypes.func.isRequired,
  nation: PropTypes.string.isRequired,
  options: PropTypes.shape({
    defaultSortName: PropTypes.string,
    defaultSortOrder: PropTypes.string
  }).isRequired,
  postingType: PropTypes.string,
  tableColumns: PropTypes.array
};

export default withNationHOC(PostingsTable);
