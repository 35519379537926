import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchAlumniPathwaysData } from '../../../services';
import { CheckboxModal } from 'components';

const AlumniWidgetBuilderOccupationsModal = ({
  configurations,
  isOpen,
  selectedPrograms,
  setConfigurations,
  toggleIsModalOpen
}) => {
  const [isError, setIsError] = useState(false);
  const [occupationsData, setOccupationsData] = useState();
  const [selectedOptions, setSelectedOptions] = useState(configurations.selectedOccupations);

  const fetchOccupationsData = async () => {
    const {
      clientId,
      geographyLevel,
      graduationTimeframeEnd,
      graduationTimeframeStart,
      hasLowConfidenceMatches,
      isOnlyAfterGraduation,
      selectedCampus,
      selectedDegreeLevel,
      selectedGeography
    } = configurations;

    const filter = {
      hasLowConfidenceMatches,
      inst_campus: selectedCampus,
      inst_program: selectedPrograms,
      inst_degree_level: selectedDegreeLevel,
      isOnlyAfterGraduation,
      graduationTimeframeStart,
      graduationTimeframeEnd
    };
    if (geographyLevel !== 'nation') {
      filter[geographyLevel] = selectedGeography;
    }

    try {
      const { occupations } = await fetchAlumniPathwaysData({
        dataType: 'occupations',
        filter,
        subdomain: clientId
      });
      setOccupationsData(occupations);

      if (configurations.selectedOccupations && configurations.selectedOccupations.length === 0) {
        setSelectedOptions(occupations.slice(0, 6));
      }
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (!occupationsData) {
      fetchOccupationsData();
    }
  }, [occupationsData]);

  return (
    <CheckboxModal
      directionsText="Select up to 6 occupations to display in your widget. At least one occupation must be selected."
      headingText="Select Occupations"
      isError={isError}
      isOpen={isOpen}
      options={occupationsData}
      selectionLimit={6}
      selectedOptions={selectedOptions}
      setSelectedOptions={selectedOptions => {
        setConfigurations({
          ...configurations,
          selectedOccupations: selectedOptions
        });
      }}
      toggleIsModalOpen={toggleIsModalOpen}
    />
  );
};

AlumniWidgetBuilderOccupationsModal.propTypes = {
  configurations: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedPrograms: PropTypes.array.isRequired,
  setConfigurations: PropTypes.func.isRequired,
  toggleIsModalOpen: PropTypes.func.isRequired
};

export default AlumniWidgetBuilderOccupationsModal;
