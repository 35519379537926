import {
  keenWidgetTotalClicksByPrograms,
  keenWidgetTotalLoadsByPrograms,
  keenWidgetTotalVisitButtonClicksByPrograms
} from '../../../services/keen/queries';
import { fetchKeenData, fetchProgramByCode } from '../../../services';
import { objectifyArray } from '../../../helpers/objectify-array';

const fetchAnalyticsData = async ({ subdomain, timeframe }) => {
  const clicksData = await fetchKeenData({
    subdomain,
    timeframe,
    ...keenWidgetTotalClicksByPrograms
  });
  const clicksDataByProgramCodeGroup = objectifyArray(clicksData, 'programs', 'result');

  const viewsData = await fetchKeenData({
    subdomain,
    timeframe,
    ...keenWidgetTotalLoadsByPrograms
  });
  const viewsDataByProgramCodeGroup = objectifyArray(viewsData, 'programs', 'result');

  const visitButtonClicksData = await fetchKeenData({
    subdomain,
    timeframe,
    ...keenWidgetTotalVisitButtonClicksByPrograms
  });
  const visitButtonClicksDataByProgramCodeGroup = objectifyArray(
    visitButtonClicksData,
    'programs',
    'result'
  );

  const viewsProgramCodeGroups = viewsData.map(({ programs }) => programs);
  const visitButtonClicksProgramCodeGroups = visitButtonClicksData.map(({ programs }) => programs);
  const clicksProgramCodeGroups = clicksData.map(({ programs }) => programs);

  const programCodeGroups = Array.from(
    new Set([
      ...viewsProgramCodeGroups,
      ...visitButtonClicksProgramCodeGroups,
      ...clicksProgramCodeGroups
    ])
  );

  return {
    programCodeGroups,
    clicksDataByProgramCodeGroup,
    viewsDataByProgramCodeGroup,
    visitButtonClicksDataByProgramCodeGroup
  };
};

const fetchProgramData = async ({ programCodeGroups, subdomain }) => {
  const programCodes = programCodeGroups.join();

  let programData;

  try {
    programData = await fetchProgramByCode({
      subdomain,
      code: programCodes
    });
  } catch ({ errors }) {
    programData = {};
  }

  let programDataByCode = {};
  for (let index = 0; index < Object.keys(programData).length; index++) {
    const currentKey = Object.keys(programData)[index];
    const { code, name, credential } = programData[currentKey];
    programDataByCode[code] = `${name}, ${credential}`;
  }
  return programDataByCode;
};

const createProgramCodeGroupTitle = ({ programCodeGroup, programDataByCode }) => {
  const individualProgramCodes = programCodeGroup.split(',').filter(code => code !== '');
  const individualProgramTitles = individualProgramCodes.map(currentProgramCode => {
    if (programDataByCode[currentProgramCode]) {
      return programDataByCode[currentProgramCode];
    }
    return currentProgramCode;
  });
  return individualProgramTitles.join(', ');
};

const formatTableData = async ({
  clicksDataByProgramCodeGroup,
  programCodeGroups,
  programDataByCode,
  viewsDataByProgramCodeGroup,
  visitButtonClicksDataByProgramCodeGroup
}) => {
  const newTableData = programCodeGroups.map(currentProgramCodeGroup => ({
    title: createProgramCodeGroupTitle({
      programCodeGroup: currentProgramCodeGroup,
      programDataByCode
    }),
    clicks: clicksDataByProgramCodeGroup[currentProgramCodeGroup] || 0,
    views: viewsDataByProgramCodeGroup[currentProgramCodeGroup] || 0,
    'visit-button-clicks': visitButtonClicksDataByProgramCodeGroup[currentProgramCodeGroup] || 0
  }));

  const sortedTableData = newTableData.sort((alpha, bravo) => bravo.views - alpha.views);

  return sortedTableData;
};

export const fetchData = async ({ subdomain, timeframe }) => {
  const {
    programCodeGroups,
    clicksDataByProgramCodeGroup,
    viewsDataByProgramCodeGroup,
    visitButtonClicksDataByProgramCodeGroup
  } = await fetchAnalyticsData({ subdomain, timeframe });

  const programDataByCode = await fetchProgramData({ programCodeGroups, subdomain });

  const tableData = await formatTableData({
    clicksDataByProgramCodeGroup,
    programCodeGroups,
    programDataByCode,
    viewsDataByProgramCodeGroup,
    visitButtonClicksDataByProgramCodeGroup
  });

  return tableData;
};
