import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchAlumniPathwaysData } from '../../../services';
import { CheckboxModal } from 'components';

const AlumniWidgetBuilderSkillsModal = ({
  configurations,
  isOpen,
  selectedPrograms,
  setConfigurations,
  toggleIsModalOpen
}) => {
  const [isError, setIsError] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(configurations.selectedSkills);
  const [skillsData, setSkillsData] = useState();

  const fetchSkillsData = async () => {
    const {
      clientId,
      geographyLevel,
      graduationTimeframeEnd,
      graduationTimeframeStart,
      hasLowConfidenceMatches,
      isOnlyAfterGraduation,
      selectedCampus,
      selectedDegreeLevel,
      selectedGeography
    } = configurations;

    const filter = {
      hasLowConfidenceMatches,
      inst_campus: selectedCampus,
      inst_program: selectedPrograms,
      inst_degree_level: selectedDegreeLevel,
      isOnlyAfterGraduation,
      graduationTimeframeStart,
      graduationTimeframeEnd
    };
    if (geographyLevel !== 'nation') {
      filter[geographyLevel] = selectedGeography;
    }

    try {
      const { skills } = await fetchAlumniPathwaysData({
        dataType: 'skills',
        filter,
        subdomain: clientId
      });
      setSkillsData(skills);

      if (configurations.selectedSkills && configurations.selectedSkills.length === 0) {
        setSelectedOptions(skills.slice(0, 6));
      }
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (!skillsData) {
      fetchSkillsData();
    }
  }, [skillsData]);

  return (
    <CheckboxModal
      directionsText="Select up to 6 skills to display in your widget, At least one skill must be selected."
      headingText="Select Skills"
      isError={isError}
      isOpen={isOpen}
      options={skillsData}
      selectionLimit={6}
      selectedOptions={selectedOptions}
      setSelectedOptions={selectedOptions => {
        setConfigurations({
          ...configurations,
          selectedSkills: selectedOptions
        });
      }}
      toggleIsModalOpen={toggleIsModalOpen}
    />
  );
};

AlumniWidgetBuilderSkillsModal.propTypes = {
  configurations: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedPrograms: PropTypes.array.isRequired,
  setConfigurations: PropTypes.func.isRequired,
  toggleIsModalOpen: PropTypes.func.isRequired
};

export default AlumniWidgetBuilderSkillsModal;
