import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, FieldArray, formValueSelector, change } from 'redux-form';
import { RadiusGroup } from 'components';
import { fetchCentroid, fetchGeoNames, searchGeos } from '../../../services';
import { isRequired } from '../../../helpers/validation';
import { color } from '../../../styles';

// Because there are so many of them, the styled components are in another file
import {
  AddNewGeography,
  AliasInput,
  Arrow,
  CenterSelect,
  Delete,
  FirstCol,
  FirstRow,
  GeoIdsSelect,
  GeoLevelSelect,
  LevelSelectWrapper,
  LevelWarning,
  Plus,
  SecondCol,
  Wrapper
} from './styled.js';

class GeoForm extends React.Component {
  static propTypes = {
    areas: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    formId: PropTypes.string.isRequired,
    level: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
    nation: PropTypes.string.isRequired,
    selectLevels: PropTypes.object.isRequired
  };

  state = {
    showLevelWarning: {}
  };

  // DO NOT REMOVE
  // This became necessary when redux-form no longer did deep state object comparison. Since our `areas`
  // objects can get pretty complicated, the shallow object comparison ensured that this component
  // would keep re-rendering infinitely. These checks prevent that.
  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    );
  }

  componentDidUpdate() {
    // make sure that there is always one form
    if (this.props.fields.length === 0) {
      this.props.fields.push({});
    }
  }

  /**
   * @method renderArrow
   * @param  {string}    type which arrow icon to render 'up' || 'down'
   * @param  {Number}    from the index coming from
   * @param  {Number}    to   the index in the array going to
   * @return {ReactElement}   returns the arrow component
   */
  renderArrow = (type, from, to) => {
    return (
      <Arrow
        type={type}
        onClick={() => this.props.fields.move(from, to)}
        size="3"
        color={color.black}
      />
    );
  };

  render() {
    const { areas, dispatch, fields, formId, selectLevels, nation } = this.props;
    const { showLevelWarning } = this.state;

    const resetGeoidFields = field => {
      dispatch(change(formId, `${field}.center`, {}));
      dispatch(change(formId, `${field}.radiusInput`, ''));
      dispatch(change(formId, `${field}.radiusRegions`, []));
      dispatch(change(formId, `${field}.geoids`, []));
    };

    const setShowLevelWarning = (index, value) => {
      this.setState({
        showLevelWarning: {
          [index]: value
        }
      });
    };

    return (
      <Fragment>
        <p>
          The locations will be displayed in the order that you create them. The the first location
          will be the default. You can use the arrows to reorder the locations.
        </p>
        {fields.map((field, index) => {
          const level = areas[index].level;
          return (
            <div key={`area-${index}`}>
              <LevelSelectWrapper>
                <Field
                  label="Geography Level"
                  name={`${field}.level`}
                  onChange={() => resetGeoidFields(field)}
                  onFocus={() => !!level && setShowLevelWarning(index, true)}
                  onBlur={() => setShowLevelWarning(index, false)}
                  items={selectLevels}
                  id={`area-level-${index}`}
                  placeholder="Select Geography Level"
                  isSearchable={false}
                  component={GeoLevelSelect}
                  validate={value => isRequired(value && value.label)}
                />
                {showLevelWarning[index] && (
                  <LevelWarning>
                    Changing Geography Level will clear Radius Center, Radius Regions, and Geo IDs
                  </LevelWarning>
                )}
              </LevelSelectWrapper>
              <Wrapper>
                {fields.length > 1 && index !== 0 && this.renderArrow('up', index, index - 1)}
                {fields.length > 1 &&
                  index !== fields.length - 1 &&
                  this.renderArrow('down', index, index + 1)}
                <FirstCol>
                  <FirstRow>
                    <Field
                      component={AliasInput}
                      label="Alias"
                      name={`${field}.name`}
                      placeholder="Enter alias for the region"
                      type="text"
                      validate={[isRequired]}
                    />
                    <Field
                      component={CenterSelect}
                      id={`area-center-${index}`}
                      label="Radius Center"
                      disabled={!level}
                      loadOptions={(value, cb) => this.loadAreas({ value, level, cb })}
                      complete
                      onChange={value => {
                        this.loadCentroid(value, `${field}.center`, level, nation);
                      }}
                      name={`${field}.center`}
                      placeholder="Search by region name/geoID"
                      type="text"
                      validate={value => {
                        return (
                          value &&
                          value.label &&
                          !areas[index].radiusRegions.length &&
                          'Must include a valid radius'
                        );
                      }}
                    />
                  </FirstRow>
                  <Field
                    component={GeoIdsSelect}
                    id={`site-geoid-${index}`}
                    label="Geo IDs"
                    disabled={!level}
                    loadOptions={(value, cb) => this.loadAreas({ value, level, cb })}
                    isMulti
                    name={`${field}.geoids`}
                    placeholder="Search by region name/geoID"
                    validate={[isRequired]}
                  />
                </FirstCol>

                <SecondCol>
                  <FieldArray
                    name={`${field}.radiusRegions`}
                    parentIndex={index}
                    component={RadiusGroup}
                    area={areas[index]}
                    areaIndex={index}
                    dispatch={dispatch}
                    formId={formId}
                    level={level && level.value}
                    nation={nation}
                  />
                </SecondCol>

                {fields.length > 1 && (
                  <Delete onClick={() => fields.remove(index)} type="close" color={color.black} />
                )}
              </Wrapper>
            </div>
          );
        })}
        <AddNewGeography
          onClick={() =>
            fields.push({
              activeRadiusIndex: 0,
              center: {
                centroid: {}
              },
              distanceUnit: nation === 'us' ? 'miles' : 'kilometers',
              geoids: [],
              level: '',
              name: '',
              radiusInput: '',
              radiusRegions: []
            })
          }
          id="add-new-geo"
          type="button"
          disabled={fields.length > 100}
        >
          <Plus type="plus" size="2" hasHover={false} color="#ffffff" />
          Add New Geography
        </AddNewGeography>
      </Fragment>
    );
  }

  /**
   * queries algolia for areas then calls getLabels to get labels
   * @method loadAreas
   * @param  {string}  value the value from the input
   * @param  {function} cb callback function to resolve the function
   * @return {function}  a function call to fetch the areas
   */
  loadAreas = ({ value, level, cb }) => {
    if (value.length > 0) {
      const { nation } = this.props;
      return searchGeos(value, level.value, nation)
        .then(response => this.getLabels(response, level.value, nation))
        .then(({ labels, res }) => {
          const options = res.map(({ child }) => ({
            value: child.id,
            label: labels[child.id] || child.id
          }));
          return options;
        });
    }
    // need resolve this function that react-select creates, hides the loader spinner
    cb(null, {
      complete: true
    });
  };

  getLabels = async (results, level, nation) => {
    const ids = results.map(result => result.child.id);
    if (ids.length > 0) {
      const labels = await fetchGeoNames(level, ids, nation).then(res =>
        res.reduce((acc, cur) => {
          acc[cur.id] = cur.displayName;
          return acc;
        }, {})
      );
      return {
        labels,
        res: results
      };
    }
  };

  loadCentroid = ({ value, centroid, label }, field, level, nation) => {
    const { formId, dispatch } = this.props;
    if (value && !centroid) {
      fetchCentroid(level.value, [value], nation).then(resp => {
        dispatch(
          // Update the radius center input with a centroid value (not shown to user)
          change(formId, field, {
            label,
            value,
            centroid: resp.centroid || {}
          })
        );
      });
    }
  };
}

export default connect((state, { formId, nation }) => {
  const selector = formValueSelector(formId);
  return {
    areas: selector(state, 'areas'),
    // figure out which nation prop to use, the one passed in from the parent is used on the location settings page
    // the other is used on the add site form where we need to change settings on the fly based if the user selects us/ca
    nation: formId === 'LocationSettingsForm' ? nation : selector(state, 'nation')
  };
})(GeoForm);
