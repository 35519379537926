import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { color } from '../../styles';
import { fetchKeenData } from '../../services';
import {
  keenWorkOpportunityUniqueViews,
  keenWorkOpportunityViews
} from '../../services/keen/queries';

import { selectSubdomain } from '../../selectors/subdomain';
import { WorkOpportunityViewsChart } from 'components';

const { darkBlue, darkGreen } = color;

const WorkOpportunityViewsChartContainer = ({ nation, subdomain, timeframe }) => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const getData = async () => {
    const userParams = {
      subdomain,
      timeframe
    };

    const pageViewsData = await fetchKeenData({
      ...userParams,
      ...keenWorkOpportunityViews
    });

    const uniqueViewsData = await fetchKeenData({
      ...userParams,
      ...keenWorkOpportunityUniqueViews
    });

    const chartData = [
      {
        label: 'Total Page Views',
        color: darkGreen,
        results: pageViewsData
      },
      {
        label: 'Unique Page Views',
        color: darkBlue,
        results: uniqueViewsData
      }
    ];

    setData(chartData);
    setIsLoaded(true);
  };

  useEffect(() => {
    setIsLoaded(false);
    getData();
  }, [subdomain, timeframe]);

  return <WorkOpportunityViewsChart data={data} isLoaded={isLoaded} nation={nation} />;
};

WorkOpportunityViewsChartContainer.propTypes = {
  nation: PropTypes.string.isRequired,
  subdomain: PropTypes.string,
  timeframe: PropTypes.shape({
    end: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired
  }).isRequired
};

export const mapStateToProps = ({ profile, sites, stats }) => {
  return {
    nation: sites.items[sites.currentSite].nation,
    subdomain: selectSubdomain(sites, profile),
    timeframe: stats
  };
};

export default connect(mapStateToProps)(WorkOpportunityViewsChartContainer);
