import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Flexbox = styled.div`
  display: flex;
  flex-grow: ${props => props.flexGrow};
  padding: 0 0.5rem;
  flex-basis: 0;
  min-width: 0;
  justify-content: flex-start;
  ${props => props.styles && props.styles}

  @media only screen and (max-width: ${props => props.maxWidth || '1023px'}) {
    ${props => props.xsStyles && props.xsStyles}
  }
`;

/* 
   You can add or override styles with the prop `styles`
   You can add specific styles for the mobile layout with the prop `xsStyles`
*/
const Col = ({ flexGrow = '1', styles, xsStyles, children, maxWidth }) => {
  return (
    <Flexbox flexGrow={flexGrow} styles={styles} xsStyles={xsStyles} maxWidth={maxWidth}>
      {children}
    </Flexbox>
  );
};

Col.propTypes = {
  flexGrow: PropTypes.string,
  styles: PropTypes.string,
  xsStyles: PropTypes.string
};

export default Col;
