import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from 'components';
import ExcelIcon from '../../../images/excel-icon.svg';
import LoadingImage from '../../../images/loading-gray.gif';

const StyledButton = styled(Button)`
  position: relative;
  height: 4rem;
  min-width: 20rem;

  line-height: 3.5rem;
  padding: 0 1.6rem;
  white-space: nowrap;
`;

const StyledExcelIcon = styled(props => <ExcelIcon {...props} />)`
  height: 18px;
  position: relative;
  margin-right: 1rem;
  top: 3px;
  fill: white;
  width: 2rem;
`;

const Loading = styled.img`
  position: relative;
  top: 2px;
  height: 3rem;
  display: inline-block;
  margin: 0 auto;
`;

const ExcelDownloadButton = ({ children, isLoading, handleClick }) => {
  const [preloadImage, setPreloadImage] = useState(new Image());

  useEffect(() => {
    preloadImage.src = LoadingImage;
  }, []);

  return (
    <Fragment>
      {!isLoading && (
        <StyledButton
          data-cy="excel-download-button"
          data-theme="primary-button"
          onClick={handleClick}
        >
          <StyledExcelIcon /> {children}
        </StyledButton>
      )}
      {isLoading && (
        <StyledButton data-theme="primary-button">
          <Loading alt="Loading" src={preloadImage.src} />
        </StyledButton>
      )}
    </Fragment>
  );
};

ExcelDownloadButton.propTypes = {
  isLoading: PropTypes.bool,
  handleClick: PropTypes.func.isRequired
};

export default ExcelDownloadButton;
