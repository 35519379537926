import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fetchAlumniPathwaysData } from '../../../services';

import { generateAlumniEmbedCode } from '../../../helpers/widget-embed-code-utils';
import {
  AlumniWidgetBuilderEmbed,
  AlumniWidgetBuilderPreview,
  AlumniWidgetBuilderSidebar,
  Layout,
  WidgetBuilderTabHeader
} from 'components';

const Wrapper = styled.div`
  max-width: 125rem;
  margin: 0 auto;
  padding: 0 2rem;
  padding-top: 2.5rem;
`;

const Card = styled.div`
  display: flex;
  max-height: 85rem;
  flex-direction: column;
  background: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  padding: 4rem 0 2.5rem 2rem;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;

  @media (min-width: 740px) {
    flex-direction: row;
  }
`;

const WidgetBuilderOutput = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

const EmbedWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const AlumniWidgetBuilder = ({ siteSettings }) => {
  const { currentSite, primaryColor } = siteSettings;

  const [configurations, setConfigurations] = useState({
    accordionHeadingText: 'Have These Skills',
    accordionType: 'skills',
    carouselHeadingText: 'Alumni work for these companies',
    clientId: currentSite,
    geographyLevel: 'nation',
    graduationTimeframeStart: '1999',
    graduationTimeframeEnd: 'current',
    hasAccordion: true,
    hasOverview: true,
    hasCarousel: true,
    hasSalaryRange: true,
    hasSalary: true,
    hasEmployedInField: true,
    hasLowConfidenceMatches: false,
    hasSubsequentAttainment: true,
    headingText: '',
    isBatch: false,
    isOnlyAfterGraduation: true,
    overviewHeadingText: 'Overview',
    primaryColor: primaryColor,
    selectedCampus: '',
    selectedDegreeLevel: '',
    selectedOccupations: [],
    selectedCompanies: [],
    selectedSkills: [],
    selectedGeography: '0',
    subsequentAttainmentLevel: 'bachelors'
  });

  const [isPreview, setIsPreview] = useState(true);
  const [campusesData, setCampusesData] = useState();
  const [degreeLevelsData, setDegreeLevelsData] = useState();
  const [programsData, setProgramsData] = useState();
  const [selectedPrograms, setSelectedPrograms] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const {
        clientId,
        geographyLevel,
        graduationTimeframeEnd,
        graduationTimeframeStart,
        hasLowConfidenceMatches,
        isOnlyAfterGraduation,
        selectedCampus,
        selectedDegreeLevel,
        selectedGeography
      } = configurations;

      const filter = {
        hasLowConfidenceMatches,
        inst_campus: selectedCampus,
        inst_program: selectedPrograms,
        inst_degree_level: selectedDegreeLevel,
        isOnlyAfterGraduation,
        graduationTimeframeStart,
        graduationTimeframeEnd
      };

      if (geographyLevel !== 'nation') {
        filter[geographyLevel] = selectedGeography;
      }

      try {
        const { campuses } = await fetchAlumniPathwaysData({
          dataType: 'campuses',
          filter,
          subdomain: clientId
        });
        const newCampusesData = campuses.map(({ id, title }) => ({
          label: title,
          value: id
        }));
        setCampusesData(newCampusesData);
      } catch (error) {
        setCampusesData([]);
      }

      try {
        const { degreeLevels } = await fetchAlumniPathwaysData({
          dataType: 'degreeLevels',
          filter,
          subdomain: clientId
        });
        const newDegreeLevelsData = degreeLevels.map(({ id, title }) => ({
          label: title,
          value: id
        }));
        setDegreeLevelsData(newDegreeLevelsData);
      } catch (error) {
        setDegreeLevelsData([]);
      }

      const { programs } = await fetchAlumniPathwaysData({
        dataType: 'programs',
        filter,
        subdomain: clientId
      });

      setProgramsData(programs);
    };
    fetchData();
  }, []);

  return (
    <Layout title="Alumni Widget Builder">
      <Wrapper>
        <Card>
          <AlumniWidgetBuilderSidebar
            configurations={configurations}
            siteSettings={siteSettings}
            setConfigurations={setConfigurations}
            campusesData={campusesData}
            degreeLevelsData={degreeLevelsData}
            programsData={programsData}
            selectedPrograms={selectedPrograms}
            setSelectedPrograms={setSelectedPrograms}
          />
          <WidgetBuilderOutput>
            <WidgetBuilderTabHeader
              configurations={configurations}
              isPreview={isPreview}
              selectedPrograms={selectedPrograms}
              setIsPreview={setIsPreview}
              siteSettings={siteSettings}
              handleExportCodeGeneration={({ configurations, siteSettings }) =>
                generateAlumniEmbedCode({
                  clientId: configurations.clientId,
                  configurations: {
                    ...configurations,
                    ...siteSettings
                  },
                  selectedPrograms
                })
              }
            />
            {!isPreview && (
              <EmbedWrapper>
                <AlumniWidgetBuilderEmbed
                  configurations={configurations}
                  selectedPrograms={selectedPrograms}
                  siteSettings={siteSettings}
                />
              </EmbedWrapper>
            )}
            <AlumniWidgetBuilderPreview
              isHidden={!isPreview}
              configurations={configurations}
              siteSettings={siteSettings}
              selectedPrograms={selectedPrograms}
            />
          </WidgetBuilderOutput>
        </Card>
      </Wrapper>
    </Layout>
  );
};

AlumniWidgetBuilder.propTypes = {
  siteSettings: PropTypes.shape({
    nation: PropTypes.string.isRequired
  })
};

export default AlumniWidgetBuilder;
