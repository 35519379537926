import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { generateAlumniEmbedCode } from '../../../helpers/widget-embed-code-utils';

import { WidgetBuilderEmbedCode } from 'components';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 57rem;
  padding: 3.5rem;
  padding-top: 1rem;
  overflow-y: scroll;
  background: white;
`;

const AlumniWidgetBuilderEmbed = ({ configurations, selectedPrograms, siteSettings }) => {
  const { isBatch } = configurations;

  if (!selectedPrograms.length) {
    return null;
  }

  const { currentSite } = siteSettings;

  return (
    <Wrapper>
      {!isBatch && (
        <WidgetBuilderEmbedCode
          id="alumni"
          embedCode={generateAlumniEmbedCode({
            clientId: currentSite,
            configurations: {
              ...siteSettings,
              ...configurations
            },
            selectedPrograms
          })}
        />
      )}
      {isBatch &&
        selectedPrograms.map((program, index) => (
          <WidgetBuilderEmbedCode
            key={`embed-code-${program.code}`}
            id={`alumni-${index}`}
            embedCode={generateAlumniEmbedCode({
              clientId: currentSite,
              configurations: {
                ...siteSettings,
                ...configurations
              },
              selectedPrograms: [program]
            })}
          />
        ))}
    </Wrapper>
  );
};

AlumniWidgetBuilderEmbed.propTypes = {
  configurations: PropTypes.shape({
    isBatch: PropTypes.bool.isRequired
  }),
  selectedPrograms: PropTypes.array.isRequired,
  siteSettings: PropTypes.object.isRequired
};

export default AlumniWidgetBuilderEmbed;
