import React from 'react';
import styled from 'styled-components';

import LightcastLogo from '../../../images/logo.png';
import { openCookieConsent } from '../../../helpers/cookie-consent';
import { Col, ExternalLink, Link, Row } from 'components';

const Wrapper = styled.div`
  padding: 4rem 0 2.5rem;
  display: flex;
  justify-content: space-between;
  border-radius: 0;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%);
  background-color: #fff;

  position: absolute;
  bottom: 0;
  width: 100%;

  @media (min-width: 1200px) {
    padding: 9rem 0 7.5rem;
  }

  &::before {
    content: '';
    display: none;
  }

  & > :first-child {
    align-self: center;
  }
`;

const FooterColumn = styled.div`
  text-align: center;
  line-height: 2rem;
  margin-bottom: 2rem;
`;

const Left = styled(FooterColumn)`
  @media (min-width: 1200px) {
    text-align: left;
  }
`;

const Right = styled(FooterColumn)`
  @media (min-width: 1200px) {
    text-align: right;
  }
`;

const SmallCaps = styled.span`
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.2rem;
  color: #62727a;

  @media (min-width: 1200px) {
    white-space: nowrap;
  }
`;

const StyledImage = styled.img`
  height: 1.6rem;
  vertical-align: text-bottom;
  margin-left: 1rem;
`;

const StyledExternalLink = styled(ExternalLink)`
  color: #62727a;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;

const Spacer = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const StyledLink = styled(Link)`
  color: #62727a;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledRow = styled(Row)`
  margin: 0 2rem;

  @media only screen and (max-width: 1200px) {
    flex-flow: column;
  }
`;

const StyledSpan = styled.span`
  white-space: nowrap;
`;

const GlobalFooter = () => {
  return (
    <Wrapper>
      <StyledRow>
        <Col xsStyles={'justify-content: center; align-content: center; '} maxWidth="1200px">
          <Left component="div" type="caption">
            <SmallCaps>
              career&nbsp;coach&nbsp;is powered by
              <StyledImage alt="Lightcast" src={LightcastLogo} />
            </SmallCaps>
          </Left>
        </Col>
        <Col
          flexGrow={'2'}
          maxWidth="1200px"
          styles={'justify-content: flex-end'}
          xsStyles={'justify-content: center; align-content: center;'}
        >
          <Right component="div" type="caption">
            <SmallCaps>
              <StyledExternalLink url="https://www.lightcast.io/privacy-policy/">
                privacy policy
              </StyledExternalLink>
              <Spacer>|&#8203;</Spacer>
              <StyledLink children="O*NET Information" to="/onet" />
              <Spacer>|&#8203;</Spacer>
              <StyledLink
                to=""
                role="button"
                onClick={e => {
                  e.preventDefault();
                  openCookieConsent();
                }}
              >
                cookie consent
              </StyledLink>
              <Spacer>
                |{' '}
                <StyledExternalLink url="mailto:customersupport@lightcast.io">
                  Contact Us
                </StyledExternalLink>
              </Spacer>
              <Spacer>|&#8203;</Spacer>
              <StyledSpan>
                &copy;copyright {new Date().getFullYear()}. all rights reserved.
              </StyledSpan>
            </SmallCaps>
          </Right>
        </Col>
      </StyledRow>
    </Wrapper>
  );
};

export default GlobalFooter;
