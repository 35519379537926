import styled from 'styled-components';
import Close from '../../../images/x.svg';
import { color } from '../../../styles/index';

const { blue, darkerGray, lightGray, sapphireD20 } = color;

export const CloseButton = styled.button`
  border: none;
  cursor: pointer;
`;

export const CloseIcon = styled(Close)`
  fill: #454545;
`;

export const DescriptionHeader = styled.h5`
  color: ${darkerGray}  
  font-weight: bold;
  font-size: 1.6rem;
`;

export const DescriptionText = styled.p`
  font-size: 1.6rem;
  line-height: 1.8rem;
`;

export const EmailText = styled.span`
  color: ${sapphireD20}  
  font-weight: bold;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem !important;
  padding: 2rem 3.5rem;
  background: ${lightGray};
`;

export const Heading = styled.h3`
  background: ${lightGray};
  font-size: 2rem;
  font-weight: 700;
`;

export const InnerWrapper = styled.div`
  padding: 2rem;
`;

export const Legend = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  padding-left: 1rem;
  background: ${({ background }) => background};
  font-size: 1.6rem;
  line-height: 2;
  border: 1px solid #acacac;
  border-radius: 4px;
`;

export const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const ModalWrapper = styled.div`
  position: absolute;
  top: 10rem;
  right: 25rem;
  width: 50rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;
  overflow: auto;
  border-radius: 5px;
  background: white;
  box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 99;

  @keyframes open {
    0% {
      opacity: 0;
      transform: translateX(5rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  -webkit-animation: open 0.5s;
`;

export const OpenModalButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 11.1rem;
  height: 3rem;
  background: none;
  border: none;
  color: ${blue};
  font-size: 1.6rem;
  font-weight: bold;
  cursor: pointer;

  svg {
    size: 10rem;
  }

  :focus {
    transform: scale(1.1);
  }

  :hover {
    transform: scale(1.1);
  }
`;

export const SectionHeader = styled.h4`
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: bold;
`;

export const StyledModal = styled.div`
  font-weight: 400;
  line-height: 3.5rem;
`;

export const Subsection = styled.div`
  margin-bottom: 2rem;
`;
