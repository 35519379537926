import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { isEmail, isRequired, isUrl } from '../../../helpers/validation';
import { createProgram, fetchProgramByCode } from '../../../services';
import { InputAtom as Input, MultiSelectDropdown, Select, Text } from 'components';
import {
  Wrapper,
  SectionHeader,
  Row,
  Label,
  TransferInputWrapper,
  TransferLabel,
  TransferButtonWrapper,
  AddTransferButton,
  DeleteTransferButton,
  WhiteXIcon,
  ButtonRow,
  CancelButton,
  SaveButton
} from './components';

const ProgramForm = ({
  categoryOptions,
  closeModal,
  fetchProgramData,
  hasManyInstitutions,
  initialValues,
  institutionsById,
  isEdit = false,
  openAddedProgramModal,
  updateProgram,
  setIsLoading
}) => {
  const { careers, ...restInitialValues } = initialValues;
  const [programFormData, setProgramFormData] = useState(restInitialValues);

  const validationErrorsInitialState = {
    name: false,
    credential: false,
    url: false,
    code: false,
    institutionId: false,
    requestInfoEmail: false,
    transfers: programFormData.transfers.map(() => ({
      name: false,
      url: false
    }))
  };
  const [validationErrors, setValidationErrors] = useState(validationErrorsInitialState);

  const initialCategories = Object.values(initialValues.categories).map(({ name, slug }) => ({
    name: name,
    id: slug
  }));
  const [selectedCategories, setSelectedCategories] = useState(initialCategories);
  const institutionOptions = Object.values(institutionsById).map(({ displayName, ipeds }) => ({
    label: displayName,
    value: ipeds
  }));

  const checkIsCodeUnique = async code => {
    /* this function makes a fetchProgramByCode call and if the we don't get back a 404, it means that code is already in use and so we return the validation error message */

    if (code === '') {
      /* a call to programs/<subdomain>?code= (no code parameter with no code) returns all programs, so in that case we don't bother with this validation and let the isRequired validation handle it. */
      return false;
    }
    const { subdomain } = initialValues;
    let isUnique = false;
    try {
      await fetchProgramByCode({
        subdomain,
        code
      });
    } catch (errorResponse) {
      const { errors } = errorResponse;
      isUnique = errors.some(({ status }) => status === 404);
    }
    if (isUnique) {
      /* returning false if there is no error is consistent with the way our validation functions work, even though based on the the function names ("isRequired", for instance) it would be more intuitive if we did the opposite. */
      return false;
    } else {
      return 'code must be unique';
    }
  };

  const checkValidation = async () => {
    const isCodeUnique = await checkIsCodeUnique(programFormData.code);

    const newValidationErrors = {
      name: isRequired(programFormData.name),
      credential: isRequired(programFormData.credential),
      url: isRequired(programFormData.url) || isUrl(programFormData.url),
      categories: isRequired(selectedCategories),
      code: !isEdit && (isCodeUnique || isRequired(programFormData.code)),
      institutionId: isRequired(programFormData.institutionId),
      requestInfoEmail: isEmail(programFormData.requestInfoEmail),
      applicationLink: isUrl(programFormData.applicationLink),
      tuitionLink: isUrl(programFormData.tuitionLink),
      transfers: programFormData.transfers.map(transfer => {
        return {
          name: isRequired(transfer.name),
          url: isRequired(transfer.url) || isUrl(transfer.url)
        };
      })
    };

    const { transfers, ...rest } = newValidationErrors;
    const transferErrors = transfers.map(transfer => Object.values(transfer)).flat();
    const hasErrors =
      [...Object.values(rest), ...transferErrors].filter(error => error !== false).length > 0;

    setValidationErrors(newValidationErrors);
    return hasErrors;
  };

  const handleSave = async () => {
    const { subdomain } = initialValues;

    const hasErrors = await checkValidation();
    if (!hasErrors) {
      const categories = selectedCategories.map(({ id }) => ({ slug: id }));
      if (!isEdit) {
        await createProgram({
          subdomain,
          data: { ...programFormData, categories }
        });
        openAddedProgramModal();
      }
      const { institutionId } = programFormData;
      let { slug } = initialValues;
      if (isEdit) {
        updateProgram({
          institutionId,
          slug,
          subdomain,
          data: { ...programFormData, categories }
        });
      }
      closeModal();
      setIsLoading(true);
      const enoughTimeForTheProgramsDbToUpdate = 100;
      setTimeout(() => fetchProgramData(), enoughTimeForTheProgramsDbToUpdate);
    }
  };

  const isPristine =
    JSON.stringify(restInitialValues) === JSON.stringify(programFormData) &&
    JSON.stringify(initialCategories) === JSON.stringify(selectedCategories);

  return (
    <Wrapper>
      <SectionHeader>Program Details</SectionHeader>
      <Row>
        <Label htmlFor="program-name">
          Program Name*
          <Input
            id="program-name"
            type="text"
            name="program-name"
            dataCy="program-name-input"
            value={programFormData.name}
            error={!!validationErrors.name}
            message={validationErrors.name}
            onChange={({ target }) => {
              setProgramFormData({ ...programFormData, name: target.value });
            }}
          />
        </Label>

        <Label htmlFor="program-credential">
          Credential*
          <Input
            id="program-credential"
            type="text"
            name="program-credential"
            dataCy="program-credential-input"
            value={programFormData.credential}
            error={!!validationErrors.credential}
            message={validationErrors.credential}
            onChange={({ target }) => {
              setProgramFormData({ ...programFormData, credential: target.value });
            }}
          />
        </Label>
      </Row>
      <Row>
        <Label htmlFor="program-url">
          URL*
          <Input
            id="program-url"
            type="text"
            name="program-url"
            dataCy="program-url-input"
            value={programFormData.url}
            error={!!validationErrors.url}
            message={validationErrors.url}
            onChange={({ target }) => {
              setProgramFormData({ ...programFormData, url: target.value });
            }}
          />
        </Label>

        <Label htmlFor="program-code">
          Program Code*
          <Input
            id="program-code"
            type="text"
            name="program-code"
            dataCy="program-code-input"
            disabled={isEdit}
            value={programFormData.code}
            error={!!validationErrors.code}
            message={validationErrors.code}
            onChange={({ target }) => {
              setProgramFormData({ ...programFormData, code: target.value });
            }}
          />
        </Label>
      </Row>
      <Row>
        {hasManyInstitutions && (
          <Label htmlFor="program-institution">
            Institution
            <Select
              background="white"
              border="1px solid #ccc"
              id="program-institution"
              onChange={({ target }) => {
                setProgramFormData({ ...programFormData, institutionId: target.value });
              }}
              dataCy="program-institution-select"
              error={!!validationErrors.institutionId}
              message={validationErrors.institutionId}
              options={institutionOptions}
              placeholder="Select"
              selection={programFormData.institutionId}
            />
          </Label>
        )}

        <Label htmlFor="program-categories_input" height="initial">
          Program Category*
          <MultiSelectDropdown
            options={categoryOptions}
            selectedOptions={selectedCategories}
            setSelectedOptions={setSelectedCategories}
          />
          {!!validationErrors.categories && (
            <span>
              <Text
                error={!!validationErrors.categories}
                type="caption"
                data-cy="program-categories-multiselect-error"
              >
                {validationErrors.categories}
              </Text>
            </span>
          )}
        </Label>
      </Row>
      <SectionHeader>Additional Details</SectionHeader>
      <Row>
        <Label htmlFor="program-request-info-email">
          Request Info Email
          <Input
            id="program-request-info-email"
            type="text"
            name="program-request-info-email"
            dataCy="program-request-info-email-input"
            value={programFormData.requestInfoEmail}
            error={!!validationErrors.requestInfoEmail}
            message={validationErrors.requestInfoEmail}
            onChange={({ target }) => {
              setProgramFormData({
                ...programFormData,
                requestInfoEmail: target.value === '' ? undefined : target.value
              });
            }}
          />
        </Label>

        <Label htmlFor="program-application-link">
          Application URL
          <Input
            id="program-application-link"
            type="text"
            name="program-application-link"
            dataCy="program-application-link-input"
            value={programFormData.applicationLink}
            error={!!validationErrors.applicationLink}
            message={validationErrors.applicationLink}
            onChange={({ target }) => {
              setProgramFormData({
                ...programFormData,
                applicationLink: target.value === '' ? undefined : target.value
              });
            }}
          />
        </Label>
      </Row>
      <Row>
        <Label htmlFor="program-request-tuition-link">
          Tuition URL
          <Input
            id="program-request-tuition-link"
            type="text"
            name="program-request-tuition-link"
            dataCy="program-request-tuition-link-input"
            value={programFormData.tuitionLink}
            error={!!validationErrors.tuitionLink}
            message={validationErrors.tuitionLink}
            onChange={({ target }) => {
              setProgramFormData({
                ...programFormData,
                tuitionLink: target.value === '' ? undefined : target.value
              });
            }}
          />
        </Label>
      </Row>
      <SectionHeader>Transfer Information</SectionHeader>
      {programFormData.transfers.length === 0 && (
        <Row>
          <TransferInputWrapper>
            <AddTransferButton
              onClick={() => {
                setProgramFormData({
                  ...programFormData,
                  transfers: [...programFormData.transfers, {}]
                });
                setValidationErrors({
                  ...validationErrors,
                  transfers: [...validationErrors.transfers, { name: false, url: false }]
                });
              }}
              data-cy="add-transfer-button"
            >
              + Add
            </AddTransferButton>
          </TransferInputWrapper>
        </Row>
      )}
      {programFormData.transfers.length > 0 &&
        programFormData.transfers.map((transfer, index) => (
          <Row key={`transfers=${index}`}>
            <TransferInputWrapper>
              <TransferLabel htmlFor={`program-transfer-url-${index}`}>
                Transfer URL
                <Input
                  id={`program-transfer-url-${index}`}
                  type="text"
                  name={`program-transfer-url-${index}`}
                  dataCy={`program-transfer-url-${index}-input`}
                  value={programFormData.transfers[index].url}
                  error={!!validationErrors.transfers[index].url}
                  message={validationErrors.transfers[index].url}
                  onChange={({ target }) => {
                    let newTransfers = programFormData.transfers;
                    newTransfers[index].url = target.value;
                    setProgramFormData({ ...programFormData, transfers: newTransfers });
                  }}
                />
              </TransferLabel>
            </TransferInputWrapper>
            <TransferInputWrapper>
              <TransferLabel htmlFor={`program-transfer-name-${index}`}>
                Transfer Name
                <Input
                  id={`program-transfer-name-${index}`}
                  type="text"
                  name={`program-transfer-name-${index}`}
                  dataCy={`program-transfer-name-${index}-input`}
                  value={programFormData.transfers[index].name}
                  error={!!validationErrors.transfers[index].name}
                  message={validationErrors.transfers[index].name}
                  onChange={({ target }) => {
                    let newTransfers = programFormData.transfers;
                    newTransfers[index].name = target.value;
                    setProgramFormData({ ...programFormData, transfers: newTransfers });
                  }}
                />
              </TransferLabel>
            </TransferInputWrapper>
            <TransferButtonWrapper>
              <AddTransferButton
                isVisible={index === programFormData.transfers.length - 1}
                onClick={() => {
                  setProgramFormData({
                    ...programFormData,
                    transfers: [...programFormData.transfers, {}]
                  });
                  setValidationErrors({
                    ...validationErrors,
                    transfers: [...validationErrors.transfers, { name: false, url: false }]
                  });
                }}
              >
                + Add
              </AddTransferButton>
              <DeleteTransferButton
                data-cy={`delete-program-transfer-name-${index}`}
                onClick={() => {
                  setProgramFormData({
                    ...programFormData,
                    transfers: programFormData.transfers.filter(
                      (transferObject, jndex) => jndex !== index
                    )
                  });
                }}
              >
                <WhiteXIcon />
              </DeleteTransferButton>
            </TransferButtonWrapper>
          </Row>
        ))}
      <ButtonRow>
        <CancelButton
          data-cy={`${isEdit ? 'edit' : 'add'}-program-modal-cancel-button`}
          onClick={closeModal}
        >
          Cancel
        </CancelButton>
        <SaveButton
          disabled={isPristine}
          data-cy={`${isEdit ? 'edit' : 'add'}-program-modal-save-button`}
          onClick={() => handleSave()}
        >
          Save Program
        </SaveButton>
      </ButtonRow>
    </Wrapper>
  );
};

ProgramForm.propTypes = {
  categoryOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]).isRequired,
  closeModal: PropTypes.func.isRequired,
  fetchProgramData: PropTypes.func.isRequired,
  hasManyInstitutions: PropTypes.bool,
  initialValues: PropTypes.shape({
    careers: PropTypes.array,
    categories: PropTypes.array,
    cip: PropTypes.string,
    code: PropTypes.string,
    credential: PropTypes.string,
    institutionId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    locations: PropTypes.array,
    name: PropTypes.string,
    slug: PropTypes.string,
    subdomain: PropTypes.string,
    transfers: PropTypes.array,
    tuitionRange: PropTypes.array,
    url: PropTypes.string
  }),
  institutionsById: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isEdit: PropTypes.bool,
  openAddedProgramModal: PropTypes.func,
  setIsLoading: PropTypes.func.isRequired,
  updateProgram: PropTypes.func.isRequired
};

export default ProgramForm;
