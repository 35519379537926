import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import QuestionIcon from '../../../images/question-mark-blue.svg';
import { HorizontalRule } from 'components';
import {
  CloseButton,
  CloseIcon,
  DescriptionHeader,
  DescriptionText,
  EmailText,
  OpenModalButton,
  Header,
  Heading,
  InnerWrapper,
  Legend,
  LegendWrapper,
  ModalWrapper,
  SectionHeader,
  StyledModal,
  Subsection
} from './styled';

const HelpModal = ({ legendData, modalData, title = 'Help' }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Fragment>
      <OpenModalButton
        aria-label="open Program Table Help Modal"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        Need Help
        <QuestionIcon />
      </OpenModalButton>
      {isModalOpen && (
        <ModalWrapper>
          <Fragment>
            <StyledModal>
              <Header>
                <Heading>{title}</Heading>
                <CloseButton
                  title="close help modal"
                  ariaLabel="close help modal"
                  onClick={() => setIsModalOpen(false)}
                >
                  <CloseIcon />
                </CloseButton>
              </Header>
              <InnerWrapper>
                <SectionHeader>Color Legend</SectionHeader>
                {legendData.map(({ background, legendText }) => (
                  <LegendWrapper>
                    <Legend background={background}>{legendText}</Legend>
                  </LegendWrapper>
                ))}
                <HorizontalRule />
                <SectionHeader>How to</SectionHeader>
                {modalData.map(({ description, subtitle }) => (
                  <Subsection>
                    <DescriptionHeader>{subtitle}</DescriptionHeader>
                    <DescriptionText>{description}</DescriptionText>
                  </Subsection>
                ))}
                <HorizontalRule />
                <SectionHeader>Have any feedback?</SectionHeader>
                <DescriptionText>
                  If you have any questions or concerns, please contact your account representative
                  or email{' '}
                  <EmailText>
                    <a href="mailto:careercoachteam@lightcast.io">careercoachteam@lightcast.io</a>
                  </EmailText>
                  .
                </DescriptionText>
              </InnerWrapper>
            </StyledModal>
          </Fragment>
        </ModalWrapper>
      )}
    </Fragment>
  );
};

HelpModal.propTypes = {
  legendData: PropTypes.array.isRequired,
  modalData: PropTypes.array.isRequired,
  title: PropTypes.string
};

export default HelpModal;
