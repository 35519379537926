import AlumniWidgetBuilder from '../../components/pages/AlumniWidgetBuilder';
import { connect } from 'react-redux';

export const mapStateToProps = ({ sites }) => {
  const { currentSite, items } = sites;
  const { nation, styles } = items[currentSite];

  const { accentColor } = styles;

  return {
    siteSettings: {
      currentSite,
      nation: nation,
      primaryColor: accentColor
    }
  };
};

export default connect(mapStateToProps)(AlumniWidgetBuilder);
