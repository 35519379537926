import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MediaQuery from 'react-media';

import { Header, SelectAutoComplete, Margin } from 'components';
import {
  DateRangePicker,
  MultischoolStatisticsDownload,
  ProfilesReportDownload,
  StatisticsDownload,
  WidgetStatisticsDownload
} from 'containers';
import Warning from '../../../images/warning-icon.svg';

const SelectAutoCompleteMargin = styled.div`
  margin-right: 2rem;
  width: 20rem;
  @media (max-width: 719px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const WarningIcon = styled(Warning)`
  width: 1.3rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  justify-content: flex-start;

  @media (max-width: 719px) {
    justify-content: center;
  }
`;

const Title = styled(Header)`
  margin-bottom: 1rem !important;

  @media (min-width: 560px) {
    margin-bottom: 0 !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  @media (max-width: 719px) {
    flex-direction: column;
  }
`;

class DateFilter extends React.Component {
  render() {
    const {
      dateRangeKeys,
      handleDateUpdate,
      hasProfileSchools,
      isDeveloper = false,
      isSuperAdmin = false,
      selectedDateRange
    } = this.props;
    return (
      <Margin value="3rem 0rem 2rem 0rem">
        <TitleWrapper>
          <Title component="h2" type="subtitle" bold>
            Select Date Range
          </Title>
        </TitleWrapper>
        <Wrapper>
          {(isSuperAdmin || isDeveloper) && <DateRangePicker />}
          {(!isSuperAdmin || isDeveloper) && (
            <SelectAutoCompleteMargin>
              <SelectAutoComplete
                onChange={val => handleDateUpdate(val)}
                items={dateRangeKeys}
                selectedValue={selectedDateRange}
                ariaLabel={`Select date range, ${selectedDateRange} currently selected`}
                placeholder=""
                isInverted
                hasNoSearch
                hrAfterIndices={[4]}
              />
            </SelectAutoCompleteMargin>
          )}
          <MediaQuery query="(min-width: 560px)">
            <Fragment>
              {location.pathname.includes('statistics') && <StatisticsDownload />}
              {location.pathname.includes('statistics') && hasProfileSchools && (
                <MultischoolStatisticsDownload />
              )}
              {location.pathname.includes('profiles') && <ProfilesReportDownload />}
              {location.pathname.includes('/widget') && <WidgetStatisticsDownload />}
            </Fragment>
          </MediaQuery>
        </Wrapper>
        {!isSuperAdmin && (
          <Margin value="2rem 0 2rem 0">
            <WarningIcon />
            &nbsp;&nbsp;&nbsp;&nbsp;For statistics over a time span greater than 6 months, please
            contact your account manager or email us at{' '}
            <a href="mailto:careercoachteam@lightcast.io">careercoachteam@lightcast.io</a>.
          </Margin>
        )}
      </Margin>
    );
  }
}

DateFilter.propTypes = {
  dateRangeKeys: PropTypes.array,
  handleDateUpdate: PropTypes.func,
  hasProfileSchools: PropTypes.bool.isRequired,
  isDeveloper: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  selectedDateRange: PropTypes.string
};

export default DateFilter;
