import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import qs from 'query-string';
import { ApprovedEmployers, Card, Header, PendingEmployers, TabButton, Bubble } from 'components';

const StyledCard = styled(Card)`
  padding-bottom: 0 !important;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Tabs = styled.div`
  flex-grow: 1;
`;

const EmployersCard = ({
  approvedEmployers,
  location,
  modalHide,
  modals,
  modalShow,
  pendingEmployers,
  updateEmployer
}) => {
  /* The url is parsed to determine the current tab.
  The url is in the format of "/analytics/employers?employers=approved&postings=pending" */
  const tabs = location.search
    ? qs.parse(location.search)
    : { employers: 'approved', postings: 'live' };
  const pendingLength = Object.keys(pendingEmployers).length;
  return (
    <StyledCard>
      <Header component="h2" type="subtitle" bold>
        Employers
      </Header>
      <ButtonWrapper>
        <Tabs>
          <TabButton
            isFirst
            selected={tabs.employers !== 'pending'}
            to={`/analytics/employers?${qs.stringify({
              ...tabs,
              employers: 'approved'
            })}`}
          >
            Approved
          </TabButton>
          <TabButton
            isLast
            selected={tabs.employers === 'pending'}
            to={`/analytics/employers?${qs.stringify({
              ...tabs,
              employers: 'pending'
            })}`}
          >
            Pending
            {pendingLength > 0 && <Bubble>{pendingLength}</Bubble>}
          </TabButton>
        </Tabs>
      </ButtonWrapper>
      {tabs.employers !== 'pending' && (
        <ApprovedEmployers
          data={approvedEmployers}
          modalHide={modalHide}
          modals={modals}
          modalShow={modalShow}
          updateEmployer={updateEmployer}
        />
      )}
      {tabs.employers === 'pending' && (
        <PendingEmployers
          data={pendingEmployers}
          modalHide={modalHide}
          modals={modals}
          modalShow={modalShow}
          updateEmployer={updateEmployer}
        />
      )}
    </StyledCard>
  );
};

EmployersCard.propTypes = {
  approvedEmployers: PropTypes.object,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  modalHide: PropTypes.func.isRequired,
  modals: PropTypes.object,
  modalShow: PropTypes.func.isRequired,
  updateEmployer: PropTypes.func.isRequired,
  pendingEmployers: PropTypes.object
};
export default EmployersCard;
