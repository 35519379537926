import styled from 'styled-components';
import { Button, FormInput, FormSelect, Icon } from 'components';
import { color } from '../../../styles';

export const Arrow = styled(Icon)`
  position: absolute;
  left: -10px;
  top: ${({ type }) => (type === 'up' ? '3rem' : '5rem')};

  @media (min-width: 800px) {
    top: ${({ type }) => (type === 'up' ? '6.5rem' : '9rem')};
    font-size: 1.5rem;
    left: -15px;
  }
`;

export const Wrapper = styled.div`
  border-bottom: 2px solid ${color.lightGray};
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  position: relative;

  @media (min-width: 800px) {
    display: flex;
  }
`;

export const Delete = styled(Icon)`
  position: absolute;
  right: -16px;
  top: 3rem;

  @media (min-width: 800px) {
    right: -7px;
    top: 7.5rem;
  }
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FirstCol = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: 100%;

  @media (min-width: 800px) {
    width: 75%;
  }
`;

export const SecondCol = styled(FirstCol)`
  border-right: 0;
  width: 100%;

  @media (min-width: 800px) {
    padding: 0;
    padding-right: 1rem;
    width: 15%;
  }
`;

export const Input = styled(FormInput)`
  margin: 0 0 1rem;
`;

export const AliasInput = styled(FormInput)`
  margin: 0 1rem 1rem 0;
  width: 50%;

  input {
    height: 4.35rem;
  }
`;

export const GeoIdsSelect = styled(FormSelect)`
  margin: 0 0 1rem;
  .Select-control {
    min-height: 12rem;
  }
  input {
    height: 3rem;
  }
  .Select-menu-outer {
    box-shadow: 0 1px 3px 0 rgba(82, 97, 115, 0.2);
  }
`;

export const CenterSelect = styled(FormSelect)`
  margin: 0 0 1rem;
  width: 50%;

  input {
    height: 3rem;
  }
  .Select-menu-outer {
    box-shadow: 0 1px 3px 0 rgba(82, 97, 115, 0.2);
  }
`;

export const LevelSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const GeoLevelSelect = styled(FormSelect)`
  margin: 0 1rem 2rem;

  @media (min-width: 800px) {
    width: 33rem;
  }
`;

export const LevelWarning = styled.div`
  color: ${color.red};
  display: flex;
  align-items: center;
`;

export const AddNewGeography = styled(Button)`
  margin-left: 1rem;
`;

export const Plus = styled(Icon)`
  margin-right: 1rem;
  vertical-align: bottom;
`;
