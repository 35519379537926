import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ColorPicker, WidgetBuilderToggle } from 'components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PickerDiv = styled.div`
  margin-left: 4.5rem;
`;

const WidgetBuilderVisitButtonToggle = ({ configurations, hasCcEnabled, setConfigurations }) => {
  const { hasVisitButton, visitButtonColor } = configurations;
  return (
    <Wrapper>
      <WidgetBuilderToggle
        id="has-visit-button"
        label="Visit Career Coach Button"
        ariaLabel={`${hasVisitButton ? 'disable' : 'enable'} Visit Career Coach Button`}
        handleChange={() =>
          setConfigurations({ ...configurations, hasVisitButton: !hasVisitButton })
        }
        isChecked={hasVisitButton}
        isDisabled={!hasCcEnabled}
      />
      {hasCcEnabled && hasVisitButton && (
        <PickerDiv>
          <ColorPicker
            id="visit-button-color-picker"
            pickedColor={visitButtonColor}
            handleChange={hex => setConfigurations({ ...configurations, visitButtonColor: hex })}
          />
        </PickerDiv>
      )}
    </Wrapper>
  );
};

WidgetBuilderVisitButtonToggle.propTypes = {
  configurations: PropTypes.shape({
    hasVisitButton: PropTypes.bool.isRequired,
    visitButtonColor: PropTypes.string
  }).isRequired,
  hasCcEnabled: PropTypes.bool.isRequired,
  setConfigurations: PropTypes.func.isRequired
};

export default WidgetBuilderVisitButtonToggle;
