import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../styles';
import CirclePlus from '../../../images/circle-plus.svg';
import { Hint } from 'components';

const { mediumDarkGray, sapphireD20 } = color;

const Heading = styled.h3`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeadingRightItems = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 0.2rem;
`;

const EditIcon = styled(CirclePlus)`
  height: 1.7rem;
  width: 1.7rem;
  position: relative;
  top: 0.4rem;
  margin-right: 0.25rem;
`;

const InsertButton = styled.button`
  border: none;
  background: none;
  color: ${sapphireD20};
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
`;

const TextArea = styled.textarea`
  width: 100%;
  margin-top: 0.5rem;
  border: 1px solid ${mediumDarkGray};
  border-radius: 3px;
`;

const Label = styled.label`
  display: block;
`;

const AlumniWidgetBuilderHeading = ({ configurations, setConfigurations, selectedPrograms }) => {
  const [widgetHeading, setWidgetHeading] = useState(configurations.headingText);
  const { isBatch } = configurations;

  useEffect(() => {
    const debouncedUpdateParentState = setTimeout(() => {
      setConfigurations({ ...configurations, headingText: widgetHeading });
    }, 400);
    return () => clearTimeout(debouncedUpdateParentState);
  }, [widgetHeading]);

  useEffect(() => {
    let defaultHeadingText;
    if (isBatch) {
      defaultHeadingText = '{ProgramName} Alumni Outcomes';
    } else if (selectedPrograms.length !== 1) {
      defaultHeadingText = 'Alumni Outcomes';
    } else {
      defaultHeadingText = `${selectedPrograms[0].name} Alumni Outcomes`;
    }
    setWidgetHeading(defaultHeadingText);
  }, [isBatch, selectedPrograms]);

  return (
    <Fragment>
      <HeadingRow>
        <Heading>Widget Heading</Heading>
        {isBatch && (
          <HeadingRightItems>
            <InsertButton
              data-cy="program-name-insert-button"
              onClick={() => {
                const newHeading = `${widgetHeading} {ProgramName}`;
                setWidgetHeading(newHeading);
              }}
              aria-label={`Insert Program name to heading text`}
            >
              <EditIcon aria-hidden="true" />
              {`Insert Program Name`}
            </InsertButton>
            <Hint id="add-program-name-tooltip">
              This inserts the program name at the end of the text entered into the header text box.
              You can move the placement of the variable by deleting, adding spaces, or copying and
              pasting it where you want the name to appear within your heading.
            </Hint>
          </HeadingRightItems>
        )}
      </HeadingRow>
      <Label>
        Edit widget heading text
        <TextArea
          id="widget-heading-text"
          rows="4"
          cols="50"
          data-cy="widget-heading-textarea"
          value={widgetHeading}
          onChange={({ target: { value } }) => setWidgetHeading(value)}
        />
      </Label>
    </Fragment>
  );
};

AlumniWidgetBuilderHeading.propTypes = {
  configurations: PropTypes.object.isRequired,
  setConfigurations: PropTypes.func.isRequired,
  selectedPrograms: PropTypes.array.isRequired
};

export default AlumniWidgetBuilderHeading;
