import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InfoModal, NoTableData, Paginate, SearchInput, Table, TextButton } from 'components';

// if I add the margin to the SearchInput directly the magnifying glass icon moves outside the input
const SearchMargin = styled.div`
  margin-top: 3rem;
  overflow-x: auto;
`;

const StyledTable = styled(Table)`
  min-width: 75rem;
`;

class EmployersTable extends React.Component {
  state = {
    searchTerm: ''
  };
  render() {
    let changePage;
    const { data, modalShow, options, statusType, tableColumns = [], ...props } = this.props;
    const { searchTerm } = this.state;
    const defaultColumns = [
      {
        isKey: true,
        dataField: 'name',
        text: 'Employer',
        dataFormat: (cell, row) => (
          <Fragment>
            <TextButton onClick={() => modalShow(`employer${row.employerId}`)}>{cell}</TextButton>
            {props.modals[`employer${row.employerId}`] && (
              <InfoModal
                name={`employer${row.employerId}`}
                title={row.name}
                entryKeys={[
                  'contactName',
                  'email',
                  'description',
                  'website',
                  'location',
                  'phoneNumber'
                ]}
                row={row}
              />
            )}
          </Fragment>
        )
      }
    ];
    return (
      <SearchMargin>
        <SearchInput
          aria-label="search employers by name or location"
          placeholder="Search by employer name or location"
          onChange={e => this.setState({ searchTerm: e.target.value })}
        />
        {this.searchEmployers(data).length > 0 ? (
          <StyledTable
            pagination
            data={this.searchEmployers(data)}
            columns={[...defaultColumns, ...tableColumns]}
            options={{
              ...options,
              page: 1,
              sizePerPage: 10,
              paginationPanel: pagination => {
                changePage = pagination.changePage;
                return (
                  <Paginate
                    page={pagination.currPage}
                    total={this.searchEmployers(data).length}
                    limit={10}
                    goToPage={pagination.changePage}
                  />
                );
              },
              onSortChange: () => changePage(1)
            }}
            {...props}
          />
        ) : (
          <NoTableData statusType={statusType} tableType="employers" isFiltered={!!searchTerm} />
        )}
      </SearchMargin>
    );
  }
  /** Convert employers from object to array, and search each employer's name and location for keyword */
  searchEmployers = data => {
    let employers = Object.values(data);
    let { searchTerm } = this.state;
    if (searchTerm) {
      searchTerm = searchTerm.toLowerCase();
      employers = employers.filter(
        i =>
          i.name.toLowerCase().includes(searchTerm) ||
          (i.location && i.location.toLowerCase().includes(searchTerm))
      );
    }
    return employers;
  };
}

EmployersTable.propTypes = {
  data: PropTypes.object.isRequired,
  tableColumns: PropTypes.array,
  options: PropTypes.object,
  modalShow: PropTypes.func.isRequired,
  statusType: PropTypes.string
};

export default EmployersTable;
