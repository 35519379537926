import React from 'react';
import styled from 'styled-components';
import { Scrollchor } from 'react-scrollchor';

const Div = styled.div`
  a {
    padding: 6px;
    position: absolute;
    top: -4rem;
    left: 0;
    color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: transparent;
    transition: top 0.1s ease-out, background 0.1s linear;
    z-index: 100;

    &:focus {
      top: 0;
      background: #b20110;
      outline: 0;
    }
  }
`;

const SkipToContent = ({ onClick }) => {
  return (
    <Div>
      <Scrollchor onClick={onClick} to="main-content">
        skip to main content
      </Scrollchor>
    </Div>
  );
};

export default SkipToContent;
