import React from 'react';
import PropTypes from 'prop-types';
import { BoldLabel, Margin, InputAtom } from 'components';
import styled from 'styled-components';

const InputWrapper = styled(Margin)`
  position: relative;
`;

const Subtitle = styled.span`
  position: absolute;
  right: 8px;
  top: 35px;
`;

const StyledInput = styled(InputAtom)`
  width: 100%;
`;

const FormInput = ({
  name,
  type,
  postfixText,
  disabled,
  meta: { error, touched },
  input,
  label,
  placeholder,
  onChange,
  maxLength,
  className,
  ...props
}) => {
  const hasError = error && touched && !disabled;
  return (
    <InputWrapper value="0 0 3rem 0" className={className}>
      <BoldLabel name={input.name} label={label} />
      <StyledInput
        onChange={onChange}
        disabled={disabled}
        maxLength={maxLength}
        id={input.name}
        error={hasError}
        message={hasError ? error : null}
        aria-label={hasError ? `Error: ${error}` : 'Enter your email'}
        name={name}
        type={type}
        {...input}
        placeholder={placeholder}
        {...props}
      />
      {postfixText && <Subtitle>{postfixText}</Subtitle>}
    </InputWrapper>
  );
};

FormInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
  id: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  postfixText: PropTypes.string,
  maxLength: PropTypes.string,
  className: PropTypes.string
};

export default FormInput;
