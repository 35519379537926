import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 4rem;
  justify-content: center;
`;

const NoProgramsMessage = styled.p`
  width: 35rem;
  font-size: 2rem;
  text-align: center;
  font-weight: 7;
`;

const EmptySelectionPrompt = () => (
  <Wrapper>
    <NoProgramsMessage>Select some Programs to start building your widget</NoProgramsMessage>
  </Wrapper>
);

export default EmptySelectionPrompt;
