import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import { withRouter } from 'react-router-dom';

import { SelectOption, Icon, HiddenLabel } from 'components';
import { flashInfo } from 'helpers/flashmessage';
import { color } from '../../../styles';

const Wrapper = styled.span`
  position: relative;
  display: block;
`;

const StyledReactSelect = styled(ReactSelect)`
  width: 100%;
  display: inline-block;

  .Select__option--is-selected {
    background: #deebff;
    color: #323232;
  }

  .Select__control {
    height: 4.4rem;
    padding-left: 2.5rem;
  }
`;

const StyledSubdomain = styled.div`
  font-size: 1.5rem;
  font-style: italic;
  color: ${color.darkGray};
`;

const StyledLabel = styled.span`
  font-size: 1.5rem;
  width: 10rem;
  text-align: left;
  color: ${color.black};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SearchIcon = styled(Icon)`
  left: 10px;
  position: absolute;
  top: 11px;
`;

const GlobalSelect = ({
  adminSubdomains,
  subdomains,
  updateCurrentSite,
  fetchSite,
  defaultValue,
  location,
  history
}) => {
  const sortedOptions = Object.values(subdomains)
    .filter(subdomain => !adminSubdomains || adminSubdomains.includes(subdomain.id))
    .sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    })
    .map(({ id, name }) => {
      return {
        value: id,
        label: name
      };
    });

  return (
    <Wrapper>
      <HiddenLabel htmlFor="global-select">Select Institution</HiddenLabel>
      <StyledReactSelect
        classNamePrefix="Select"
        isClearable={false}
        options={sortedOptions}
        onChange={option => {
          // Check to make sure option exists first (i.e., you can keep hitting backspace and force onChange events)
          if (option !== undefined && option !== null) {
            updateCurrentSite(option.value);
            fetchSite(option.value);
            flashInfo(`${option.value} site is now selected.`);

            // On mobile screen (where Change Institution is on a separate change-site page), redirect to Analytics page upon site change.
            location.pathname.includes('change-site') && history.push('/analytics/statistics');
          }
        }}
        inputProps={{ id: 'global-select', autoComplete: 'off' }}
        value={
          sortedOptions.find(option => {
            return option.value === defaultValue;
          }) || null
        }
        optionComponent={SelectOption}
        components={{
          SingleValue: ({ data }) => <StyledLabel>{data.label}</StyledLabel>,
          IndicatorSeparator: () => null
        }}
        formatOptionLabel={option => (
          <div>
            <strong>{option.label}</strong>
            <StyledSubdomain>
              {option.value}
              {'.lightcastcc.com'}
            </StyledSubdomain>
          </div>
        )}
        getValue={selected => selected.map(option => option.label)}
      />
      <SearchIcon size="2" type="search" color={color.darkGray} hasHover={false}>
        Search
      </SearchIcon>
    </Wrapper>
  );
};

GlobalSelect.propTypes = {
  adminSubdomains: PropTypes.array,
  defaultValue: PropTypes.string.isRequired,
  fetchSite: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  subdomains: PropTypes.object.isRequired,
  updateCurrentSite: PropTypes.func.isRequired
};

export default withRouter(GlobalSelect);
