import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ExcelDownloadButton, ExcelEmployersWorkbook, HideContentOnMobile } from 'components';

const EmployerInfoDownload = ({ employers, postings, shouldDownload, triggerDownload }) => {
  return (
    <Fragment>
      <ExcelDownloadButton handleClick={triggerDownload}>
        Download Employer Info
      </ExcelDownloadButton>
      {shouldDownload && <ExcelEmployersWorkbook employers={employers} postings={postings} />}
    </Fragment>
  );
};

EmployerInfoDownload.propTypes = {
  employers: PropTypes.object.isRequired,
  postings: PropTypes.object.isRequired,
  shouldDownload: PropTypes.bool,
  triggerDownload: PropTypes.func.isRequired
};

EmployerInfoDownload.defaultProps = {
  shouldDownload: false
};

export default EmployerInfoDownload;
