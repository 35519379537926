import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ConfirmationModal, WidgetBuilderToggle } from 'components';
import { color } from '../../../styles';

const { red } = color;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const StyledLabel = styled.label`
  font-weight: 600;
`;

const StyledInput = styled.input`
  width: 100%;
  margin-bottom: 2rem;
  height: 4rem;
  font-size: 1.4rem;

  &::placeholder {
    color: ${color.darkGray};
  }
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  width: 10rem;
`;

const WidgetBuilderStylesheetHref = ({
  configurations,
  modalHide,
  modals,
  modalShow,
  setConfigurations
}) => {
  const { stylesheetHref } = configurations;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [localStylesheetHref, setLocalStylesheetHref] = useState(stylesheetHref);
  return (
    <Fragment>
      <WidgetBuilderToggle
        id="developer"
        label="Developer"
        handleChange={() => {
          if (!isDrawerOpen) {
            modalShow('widget-builder-developer-disclaimer');
          } else {
            setIsDrawerOpen(false);
          }
        }}
        isChecked={isDrawerOpen}
      />
      {isDrawerOpen && (
        <InputWrapper>
          <StyledLabel htmlFor="stylesheet-href">Custom Stylesheet Link</StyledLabel>
          <StyledInput
            id="stylesheet-href"
            value={localStylesheetHref}
            onChange={({ target }) => setLocalStylesheetHref(target.value)}
            placeholder="https://my-college-domain.com/my-stylesheet.css"
          />
          <StyledButton
            onClick={() =>
              setConfigurations({
                ...configurations,
                stylesheetHref: localStylesheetHref
              })
            }
          >
            Apply
          </StyledButton>
        </InputWrapper>
      )}
      {modals['widget-builder-developer-disclaimer'] && (
        <ConfirmationModal
          name="widget-builder-developer-disclaimer"
          heading="Enable Developer Settings?"
          modalHide={modalHide}
          text={`If Licensee uses custom stylesheets in the widget, Lightcast cannot guarantee
          accessibility or compatibility with the widget and any future updates.`}
          confirmationButton={
            <Button
              color="#ffffff"
              background={red}
              onClick={() => {
                setIsDrawerOpen(true);
                modalHide('widget-builder-developer-disclaimer');
              }}
            >
              Yes
            </Button>
          }
        />
      )}
    </Fragment>
  );
};

WidgetBuilderStylesheetHref.propTypes = {
  configurations: PropTypes.shape({
    stylesheetHref: PropTypes.string
  }).isRequired,
  modalHide: PropTypes.func.isRequired,
  modals: PropTypes.object.isRequired,
  modalShow: PropTypes.func.isRequired,
  setConfigurations: PropTypes.func.isRequired,
  siteSettings: PropTypes.shape({
    nation: PropTypes.string.isRequired
  }).isRequired
};

export default WidgetBuilderStylesheetHref;
