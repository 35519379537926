import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styled from 'styled-components';

import { color } from 'styles';
const { black, lighterBlue } = color;

const StyledPillGroup = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 2.75rem;
  margin: 0.1rem 1rem 0.3rem 0;
  padding: 1rem;
  border-radius: 30px;
  background: ${lighterBlue};
  color: ${black};
  font-size: 1.4rem;
  font-weight: 100;
`;

const StyledX = styled.button`
  position: absolute;
  top: 0;
  right: -1.6rem;
  display: flex;
  width: 2.4rem;
  height: 2.4rem;
  background-color: transparent;
  border: none;
  margin-bottom: 2rem;
  cursor: pointer;
  color: ${black};
  font-size: 1rem;
`;

const StyledText = styled.span`
  flex-grow: 1;
  white-space: nowrap;
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  background: ${({ isFocused }) => (isFocused ? lighterBlue : 'white')};
  color: ${black};
`;

const CustomOption = ({ data, isSelected, isFocused, innerProps }) => {
  const listItemRef = useRef(null);

  return (
    <StyledListItem
      ref={listItemRef}
      isSelected={isSelected}
      isFocused={isFocused}
      {...innerProps}
      aria-selected={isSelected}
    >
      <label>
        <input type="checkbox" checked={isSelected} readOnly />
        {data.label}
      </label>
    </StyledListItem>
  );
};

const MultiSelectDropdown = ({ options, selectedOptions, setSelectedOptions }) => {
  const handleSelectData = data => data.map(({ name, id }) => ({ label: name, value: id }));

  const handleChange = selected => {
    const transformed = selected.map(({ label, value }) => ({ name: label, id: value }));
    setSelectedOptions(transformed);
  };

  const OptionPill = props => {
    const { data, removeProps } = props;
    return (
      <StyledPillGroup>
        <StyledText>{data.label}</StyledText>
        <StyledX onClick={removeProps.onClick}>✖</StyledX>
      </StyledPillGroup>
    );
  };

  return (
    <Select
      closeMenuOnSelect={false}
      components={{
        ClearIndicator: () => null,
        IndicatorSeparator: () => null,
        MultiValue: OptionPill,
        Option: CustomOption
      }}
      tabSelectsValue={false}
      hideSelectedOptions={false}
      id="program-categories"
      inputId="program-categories_input"
      isMulti
      isSearchable
      options={handleSelectData(options)}
      onChange={handleChange}
      placeholder=""
      value={handleSelectData(selectedOptions)}
    />
  );
};

MultiSelectDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  setSelectedOptions: PropTypes.func.isRequired
};

export default MultiSelectDropdown;
